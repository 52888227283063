"use client";

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Check,
  ChevronsUpDown,
  CircleGauge,
  Megaphone,
  FileLineChart,
  Settings,
  BadgeHelp,
  BadgeCheck,
  TrendingUp,
  DollarSign,
  Headset,
  ShoppingCart,
  PencilIcon,
  PlusIcon,
  Trash,
  DollarSignIcon,
  ChevronDown,
  ArrowUp,
  ArrowDown,
  Clock,
  Phone,
  MessageSquareMoreIcon,
} from "lucide-react";
import Link from "next/link";

import google from "../assets/google.svg";

export const ICON_MAP = {
  "badge-check": BadgeCheck,
  "badge-help": BadgeHelp,
  check: Check,
  "chevrons-up-down": ChevronsUpDown,
  "chevron-down": ChevronDown,
  "circle-gauge": CircleGauge,
  "dollar-sign": DollarSign,
  "file-line-chart": FileLineChart,
  google: google,
  headset: Headset,
  megaphone: Megaphone,
  settings: Settings,
  "shopping-cart": ShoppingCart,
  "trending-up": TrendingUp,
  pencil: PencilIcon,
  plus: PlusIcon,
  trash: Trash,
  dollar: DollarSignIcon,
  clock: Clock,
  "arrow-up": ArrowUp,
  "arrow-down": ArrowDown,
  message: MessageSquareMoreIcon,
  phone: Phone,
};

export type IIconName = keyof typeof ICON_MAP;

interface IIconProps extends React.SVGProps<SVGSVGElement> {
  name: IIconName;
  href?: string;
  className?: string;
}

const Icon: React.FC<IIconProps> = ({ name, href, className = "" }) => {
  const IconComponent = ICON_MAP[name];

  if (!!href) {
    return (
      <Link href={href}>
        <IconComponent className={className} />
      </Link>
    );
  }

  return <IconComponent className={className} />;
};

export { Icon };
