import React from "react";

import { cn } from "../libs/utils";

const Badge = ({
  text,
  variance,
}: {
  text?: string;
  variance: "primary" | "warning" | "danger";
}) => {
  //if text is falsey, return a rounded badge, else return a rounded badge with text
  return (
    <div
      className={cn("min-w-[8px] rounded-full", {
        "w-fit px-2 py-[2px] text-center text-xs": !!text,
        "h-2 w-2": !text,
        "bg-primary text-white": variance === "primary",
        "bg-yellow-400 text-white": variance === "warning",
        "bg-red-500 text-white": variance === "danger",
      })}
    >
      {text}
    </div>
  );
};

export { Badge };
